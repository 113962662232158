import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import Register from "./register/register";
import { AuthContext } from 'src/context/Auth';
import CloseIcon from '@material-ui/icons/Close';
import Login from "./login";
import OTP from "./register/otp";
const useStyles = makeStyles((theme) => ({
    openButton: {
        background: "#fff",
        width: "100%",
        height: 40
    },
    dialog: {
        width: "100%",
        "& .MuiDialog-paperWidthSm": {
            width: "100%",
            maxWidth: 741,
            background: "#0E0E0E",
            padding: "40px 80px 10px",
            [theme.breakpoints.down('sm')]: {
                padding: "20px",
                margin: 0,
                height:"100%"
                // maxHeight: "calc(100% - 19px)"

            },
        }
    },
    main: {
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        alignItems: "center",
        position: "relative",
        // [theme.breakpoints.down('sm')]: {
        //     padding: "10px",
        // },
    },
    headingDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
        "& img": {
            width: "200px"
        }
    },
    heading: {
        color: "#F6F6F6",
        fontSize: "larger",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "141.023%",
        "& span": {
            color: "#FFA068"
        }
    },

    footer: {

    },
    footerText: {
        color: "#7B7B7B",
        fontFamily: "IBM Plex Sans",
        fontSize: 12.255,
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "141.023%",
        textAlign: "center",
        "& span": {
            color: "#F6F6F6",
            textDecorationLine: "underline"
        }
    },
    otpEmail: {
        color: "#BFBFBF",
        fontSize: "medium",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        textAlign: "center"

    },
    closeIcon: {
        color: "#fff",
        position: "absolute",
        right: "-68px",
        top: "-28px",
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            right: "-14px",
            top: "-14px",
        }
    }
}));
export default function CustomDialog() {
    const Auth = useContext(AuthContext);
    const { open, authType, handleAuthOpen, handleAuthClose, setAuthType } = Auth;
    const classes = useStyles();

    return (
        <div >
            {/* <button className={classes.openButton} onClick={handleAuthClose}>
                Open dialog
            </button> */}

            <Dialog className={classes.dialog} aria-labelledby="customized-dialog-title" open={open}>
                <div className={classes.main}>
                    <CloseIcon className={classes.closeIcon} onClick={handleAuthClose} />
                    <div className={classes.headingDiv}>
                        <img src="/favicon.svg" alt="logo" />

                        {authType == "LOGIN" && <h3 className={classes.heading}>Log in to <span>your account</span></h3>}
                        {authType == "REGISTER" && <h3 className={classes.heading}><span>Sign Up</span> to Saturn</h3>}
                        {authType == "OTP" && <h3 className={classes.heading}>You are just a step away</h3>}
                        {authType == "OTP" && <p className={classes.otpEmail}>we have sent an OTP to {window.localStorage.getItem("email")}</p>}
                    </div>
                    {authType == "LOGIN" && <Login setAuthType={setAuthType} />}
                    {authType == "REGISTER" && <Register setAuthType={setAuthType} />}
                    {authType == "OTP" && <OTP setAuthType={setAuthType} handleAuthClose={handleAuthClose} />}
                </div>
                <br />   <br />
                <footer className={classes.footer}>
                    <p className={classes.footerText}>By clicking on Sign Up, you agree to SCGs <span>Terms and Conditions of Use</span></p>
                </footer>
            </Dialog>

        </div>
    );
}

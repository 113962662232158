/* eslint-disable no-use-before-define */
import React, { useState, useContext, useEffect } from "react";
import { matchPath } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  List,
  makeStyles,
  Avatar,
  Typography,
  Button,
  Grid,
} from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import NavItem from "src/Layouts/DashboardLayout/NavBar/NavItem";
import { useHistory } from "react-router-dom";
import ConfirmationDialog from "src/Components/ConfirmationDialog";
import { AuthContext } from "src/context/Auth";
import {
  FaSignOutAlt,
  FaUserEdit,
} from "react-icons/fa";

const sections = [
  {
    title: "Profile",
    href: "/profile",
    icon: FaUserEdit,
  },

  {
    title: "Logout",
    href: "/dashboard",
    icon: FaSignOutAlt,
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    padding: "14px",
    position: "absolute",
    right: 0,
    top: 30,
    width: 314,
    overflow: "auto",
    overflow: "unset",
    borderRadius: 25,
    background: "#0E0E0E",
    // boxShadow: "0px 0px 13px 0px rgba(246, 246, 246, 0.20)",
  },
  avatar: {
    cursor: "pointer",
    width: 30,
    height: 30,

  },
  avatarBig: {
    cursor: "pointer",
    width: 70,
    height: 70,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  name: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.02em",
    textAlign: "end",
    color: "#FFFFFF",
  },
  email: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0.02em",
    color: "#FFFFFF",
    textAlign: "end",
  },
  editButton: {
    width: "96px",
    height: "29px",
    left: "34px",
    background: "#f6f6f6",
    borderRadius: "10px",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    letterSpacing: "0.02em",
    color: "#000000",
    "&:hover": {
      background: "#f6f6f6",
    }
  },
  userButton: {
    width: "100%",
    maxWidth: "142px",
    height: "44.7px",
    background: "#F2F5FA",
    borderRadius: "27px",
    display: "flex",
    justifyContent: "space-around",
    paddingLeft: "50px",
    fontStyle: "normal",
    fontWeight: "500",
    // fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.02em",
    color: "#000000",
    "&.MuiButton-root:hover": {
      backgroundColor: "white",

    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: "89px",
      fontSize: "10px",
      paddingLeft: "28px"

    },
    [theme.breakpoints.only('sm')]: {
      // padding: "110px 10px 10px ",
    },
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const profileData = useContext(AuthContext);
  const [rightBar, setRightBar] = useState(false);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const name = profileData.profile.name ? profileData.profile.name : "";
  const email = window.localStorage.getItem("email");
  const confirmationHandler = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("email");
    history.push("/");
    window.localStorage.clear();
  };
useEffect(()=>{
  profileData.getProfile();
},[])
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"Logout"}
          desc={"Do you want to logout ?"}
          confirmationHandler={confirmationHandler}
        />
      )}

      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Avatar
            src="/images/dummyUser.svg"
            className={classes.avatarBig}
            onClick={() => {
              setRightBar(!rightBar);
            }}
          />
        </Grid>

        <Grid item xs={9}>
          <Box pr={3} p={2}>
            <Typography className={classes.name}>
              {name ? name : "User"}
            </Typography>
            <Typography className={classes.email}>{email ? email : ""}
            </Typography>
          </Box>
        </Grid>
        {sections.map((section, i) => {
          const Icon = section.icon;
          return (
            <Grid item xs={6}>
              <Box pt={1}>
                <Button
                  className={classes.editButton}
                  fullWidth
                  key={i}
                  onClick={() => {
                    section.title === "Logout"
                      ? setOpen(true)
                      : history.push(section.href);
                  }}
                >
                  {section.title}{" "}
                </Button>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
  const res = "User"
  return (
    <>
      <Avatar
        src="/images/dummyUser.svg"
        className={classes.avatar}
        onClick={() => {
          setRightBar(!rightBar);
        }}
      />

      <Dialog
        classes={{ paper: classes.desktopDrawer }}
        open={rightBar}
        onClose={() => {
          setRightBar(false);
        }}
      >
        {content}
      </Dialog>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;

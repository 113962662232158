import React, { useState, useContext } from 'react';
import {
    TextField,
    Button,
    makeStyles,
    FormHelperText,
    FormControl,
    CircularProgress
} from "@material-ui/core";
import { Form, Formik, Field } from "formik";
import toast from 'react-hot-toast';
import * as yep from "yup";
// import ButtonCircularProgress from "src/Components/ButtonCircularProgress";
import ApiConfig from 'src/config/APIConfig';
import { postRequest } from 'src/service';
import { AuthContext } from 'src/context/Auth';
const useStyles = makeStyles((theme) => ({
    formDiv: {
        display: "flex",
        flexDirection: "column",
        gap: 20,
        alignItems: "center",
        width:"100%",
        "& form":{
            width:"100%"
        }
    },
    TextBox: {
        borderRadius: 9.522,
        border: "0.952px solid #7B7B7B",
        background: "#0E0E0E",
        '&::placeholder': {
            color: 'rgba(255, 255, 255, 0.40) !important',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '24px',
        },
        "& .MuiOutlinedInput-notchedOutline": {
            top: "0px !important"
        },
        '& .MuiInputBase-input': {
            color: '#fff !important',
            // height: '24px'
        }
    },
    buttonbox: {
        width: "100%",
        padding: "2.5px 36px",
        border: "none",
        borderRadius: 9.522,
        background: "#BFBFBF",
        color: "#fff",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "700",
        marginTop: 30,
    },
    customLabel: {
        color: "#F6F6F6",
        fontFamily: "IBM Plex Sans",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "141.023%"
    },
    labelBox: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: "center"
    },
    logInButtonBox: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    loginLabel: {
        color: "#BFBFBF",
        fontFamily: "IBM Plex Sans",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "141.023%"
    }
}));






export default function Register({ setAuthType, handleAuthClose }) {
    const auth = useContext(AuthContext);
    const classes = useStyles();
    const [isloading, setLoader] = useState(false);

    const formInitialSchema = {
        email: "",
    };
    const login = async (values) => {
        setLoader(true);
        try {
            const url = ApiConfig.login;
            const postData = values;
            const res = await postRequest(url, postData);
            console.log('POST request successful:', res);
            if (res.responseCode === 403) {
                toast.error(res.message);
            }
            else {
                window.localStorage.setItem("email", res.responseData.email);
                setAuthType("OTP");
                toast.success(res.message)
            }
            setLoader(false);
        } catch (error) {
            toast.error("POST request failed")
            setLoader(false);
        }
    };
    return (
        <div className={classes.formDiv}>
            <Formik
                onSubmit={(values) =>
                    login(values)}

                initialValues={formInitialSchema}
                initialStatus={{
                    success: false,
                    successMsg: "",
                }}
                validationSchema={yep.object().shape({

                    email: yep
                        .string()
                        .email(
                            "You have entered an invalid email address. Please try again"
                        )
                        .required("Please enter your email address")
                        .matches(
                            "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$",
                            "please enter your valid email"
                        ),

                })}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                }) => (
                    <Form>
                        <FormControl fullWidth >
                            <div className={classes.labelBox}>
                                <label className={classes.customLabel}>
                                    Email<span style={{ color: "red" }}>*</span>
                                </label>
                                <FormHelperText
                                    error
                                    style={{ fontSize: "11px" }}
                                >
                                    {touched.email && errors.email}
                                </FormHelperText>
                            </div>
                            <TextField
                                placeholder="mail@website.com"
                                type="text"
                                variant="outlined"
                                fullWidth
                                id="email"
                                size="small"
                                inputProps={{ maxLength: 256 }}
                                autoComplete='off'
                                value={values.email}
                                name="email"
                                error={Boolean(touched.email && errors.email)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                    className: classes.TextBox,
                                }}
                            />

                        </FormControl>

                        <button
                            type="submit"
                            className={classes.buttonbox}
                            disabled={isloading}
                        >
                            {isloading ? "..." : "Get OTP"}
                            
                        </button>
                    </Form>
                )}
            </Formik>

            <img style={{width:"100%"}} src="/images/dashboardIcon/orLine.svg" alt="OrLine" />
            <div className={classes.logInButtonBox}>
                <label className={classes.loginLabel}>
                    Don’t have an account?
                </label>
                <button
                    className={classes.buttonbox}
                    style={{ marginTop: 0 }}
                    onClick={() => setAuthType("REGISTER")}
                >
                    Register Now
                </button>
            </div>


        </div>
    );
}

export function logoutAfterDelay(){
    // Set the timeout for 5 hours (in milliseconds)
    const logoutTimeout = 5 * 60 * 60 * 1000;
    // const logoutTimeout = 1 * 60 * 1000;

    // Store the current timestamp in local storage
    const logoutTime = new Date().getTime();
    localStorage.setItem('logoutTime', logoutTime);

    // Set a timeout to logout the user after the specified duration
    setTimeout(() => {
        // Check if the stored logout time is within the allowed duration
        const storedLogoutTime = parseInt(localStorage.getItem('logoutTime'), 10);
        const currentTime = new Date().getTime();

        if (currentTime - storedLogoutTime >= logoutTimeout) {
            // Clear the user data from local storage
            localStorage.removeItem('token');
            window.localStorage.clear();
            
            // You can also clear other user-related data if needed
            // localStorage.removeItem('username');
            // localStorage.removeItem('userId');

            // Redirect the user to the login page or perform any other logout actions
            window.location.href = '/';  // Replace '/login' with the actual login page URL
        }
    }, logoutTimeout);
}

// Example usage:
// Call this function when the user logs in or whenever appropriate
// logoutAfterDelay();

import axios from 'axios';
import toast from 'react-hot-toast';
const token = window.localStorage.getItem("token");
const getRequest = async (url) => {
  try {
    const response = await axios.get(url)
    return response.data;
  } catch (error) {
    console.error('Error making GET request:', error);
    throw error;
  }
};
const getRequestWithToken = async (url) => {
  const token = window.localStorage.getItem("token");
  try {
    const response = await axios.get(url, {
      headers: {
        token: token
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error making GET request:', error);
    throw error;
  }
};
const postRequest = async (url, data) => {
  try {
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    console.error('Error making POST request:', error);
    throw error;
  }
};

const postRequestWithToken = async (url, data) => {
  const token = window.localStorage.getItem("token");
  try {
    const response = await axios.post(url, data, {
      headers: {
        token: token
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error making POST request:', error);
    throw error;
  }
};

const deleteRequest = async (url) => {
  try {
    const response = await axios.delete(url);
    return response.data;
  } catch (error) {
    console.error('Error making DELETE request:', error);
    throw error;
  }
};
const getDocument = async (url, fileKey) => {
  try {
    const response = await axios.get(url,
      {
        params: {
          key: fileKey
        }
      });
    return response.data;
  } catch (error) {
    console.error('Error making GET request:', error);
    throw error;
  }
};

const handleDownload = async (endpoint, fileKey) => {
  const data = endpoint == "https://apii.saturnnri.com/api/generate-meeting-pdf" ? { meetId: fileKey } : {
    key: fileKey
  }
  try {
    const response = await axios.get(endpoint, {
      responseType: 'blob', // Ensure response type is blob for file download
      params: data
    });
    console.log(response, "response")
    // Create a URL for the file blob received from the server
    const url = window.URL.createObjectURL(new Blob([response.data]));

    // Create a temporary link element to trigger the file download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'filename.pdf'); // Set desired filename here
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};
const actionKYC = async (url) => {
  try {
    const response = await axios.get(url, {
      headers: {
        token: token
      }

    });
    return response.data;
  } catch (error) {
    console.error('Error making GET request:', error);
    throw error;
  }
};

export { getRequest, getRequestWithToken, postRequest, postRequestWithToken, deleteRequest, getDocument, actionKYC, handleDownload };



// Live
const url = "https://apii.saturnnri.com/api";

// const url = "http://localhost:6060/api";
const auth = `${url}/auth`;

const ApiConfig = {
  // new
  login: `${auth}/send-otp`,
  register:`${auth}/signup`,
  verifyOtp:`${auth}/verify-otp`,
  createProfile:`${url}/create-profile`,
  getProfile:`${url}/get-profile`,
  createOrder:`${url}/create-order`,
  paymentSuccess:`${url}/payment/success`,
  getAvailability:`${url}/get-availability`,
  getMeetings:`${url}/get-meetings`,
  uploadKycDocuments:`${url}/uploadKycDocuments`,
  userList:`${url}/users`,
  kycList:`${url}/kyc-users`,
  document:`${url}/document`,
  approveKYC:`${url}/approve-kyc`,
  declineKYC:`${url}/decline-kyc`,
  addReferral:`${url}/add-referral`,
  getUserReferral:`${url}/get-user-referralls`,
  referralListAdmin:`${url}/referrals`,
  approveReferral:`${url}/approve-referral`,
  declineReferral:`${url}/unapprove-referral`,
  addRegulatory:`${url}/add-regulatory`,
  addTaxation:`${url}/add-taxation`,
  generateMeetingPdf:`${url}/generate-meeting-pdf`,
  getAdminMeetings:`${url}/get-admin-meetings`,
  addMOM:`${url}/add-meeting-minutes`,
  emailUploadFiles:`${url}/email-upload-files`,
  userDetail:`${url}/user`,
  addInvestment:`${url}/add-investment`,
  getInvestment:`${url}/get-investments`


};

export default ApiConfig;

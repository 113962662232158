import React, { useState, useContext } from 'react';
import {
    TextField,
    Button,
    makeStyles,
    FormHelperText,
    FormControl,
    CircularProgress
} from "@material-ui/core";
import { Form, Formik, Field } from "formik";
import toast from 'react-hot-toast';
import * as yep from "yup";
import ButtonCircularProgress from "src/Components/ButtonCircularProgress";
import ApiConfig from 'src/config/APIConfig';
import { postRequest } from 'src/service';
import { AuthContext } from 'src/context/Auth';
import { useHistory } from 'react-router-dom';
import { logoutAfterDelay } from "src/Components/sessionLogout";
const useStyles = makeStyles((theme) => ({
    formDiv: {
        display: "flex",
        flexDirection: "column",
        gap: 20,
        alignItems: "center",
        width:"100%",
        "& form":{
            width:"100%"
        }
    },
    TextBox: {
        borderRadius: 9.522,
        border: "0.952px solid #7B7B7B",
        background: "#0E0E0E",
        '&::placeholder': {
            color: 'rgba(255, 255, 255, 0.40) !important',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '24px',
        },
        "& .MuiOutlinedInput-notchedOutline": {
            top: "0px !important"
        },
        '& .MuiInputBase-input': {
            color: '#fff !important',
            // height: '24px'
        }
    },
    buttonbox: {
        width: "100%",
        padding: "2.5px 36px",
        border: "none",
        borderRadius: 9.522,
        background: "#BFBFBF",
        color: "#fff",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "700",
        marginTop: 30
    },
    customLabel: {
        color: "#F6F6F6",
        fontFamily: "IBM Plex Sans",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "141.023%"
    },
    labelBox: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: "center"
    },
    logInButtonBox: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    loginLabel: {
        color: "#BFBFBF",
        fontFamily: "IBM Plex Sans",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "141.023%"
    }
}));



const Register = ({ setAuthType, handleAuthClose }) => {
    const auth = useContext(AuthContext);
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const formInitialSchema = {
        otp: "", // Adding OTP field to the form
    };

    const login = async (values) => {
        setIsLoading(true);
        try {
            const url = ApiConfig.verifyOtp;
            const postData = {
                email: window.localStorage.getItem("email"),
                otp: values.otp
            };
            const res = await postRequest(url, postData);
            console.log('POST request successful:', res);
            if (res.responseCode === 403) {
                toast.error(res.message);
            } else {
                logoutAfterDelay();
                handleAuthClose();
                window.localStorage.setItem("first", res.responseData.first_call);
                window.localStorage.setItem("role", res.responseData.role);
                { res.responseData.role === "ADMIN" ? history.push("/user-management") : history.push("/dashboard"); }
                auth.userLogin(res.responseData.token, true)
                toast.success(res.message);
            }
            setIsLoading(false);
        } catch (error) {
            toast.error("POST request failed");
            setIsLoading(false);
        }
    };
    return (
        <div className={classes.formDiv}>
            <Formik
                onSubmit={(values) => login(values)}
                initialValues={formInitialSchema}
                validationSchema={yep.object().shape({
                    otp: yep
                        .string()
                        .required("Please enter the OTP")
                        .matches(/^\d{4}$/, "OTP must be 4 digits"),
                })}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                }) => (
                    <Form>
                        <FormControl fullWidth>
                            <div className={classes.labelBox}>
                                <label className={classes.customLabel}>
                                    OTP<span style={{ color: "red" }}>*</span>
                                </label>
                                <FormHelperText error style={{ fontSize: "11px" }}>
                                    {touched.otp && errors.otp}
                                </FormHelperText>
                            </div>
                            <TextField
                                placeholder="Enter OTP"
                                type="text"
                                variant="outlined"
                                fullWidth
                                id="otp"
                                size="small"
                                inputProps={{ maxLength: 4 }}
                                value={values.otp}
                                name="otp"
                                autoComplete='off'
                                error={Boolean(touched.otp && errors.otp)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                    className: classes.TextBox,
                                }}
                            />
                        </FormControl>
                        <button
                            type="submit"
                            className={classes.buttonbox}
                            disabled={isLoading}
                        >
                            {isLoading ? "..." : "Verify OTP"}

                        </button>
                    </Form>
                )}
            </Formik>
            {/* <img src="/images/dashboardIcon/OrLine.svg" alt="OrLine" />
        <div className={classes.logInButtonBox}>
            <label className={classes.loginLabel}>
                Don’t have an account?
            </label>
            <Button
                className={classes.buttonbox}
                style={{ marginTop: 0 }}
                onClick={() => setAuthType("REGISTER")}
            >
                Register Now
            </Button>
        </div> */}
        </div>
    );
}
export default Register;
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import ConfirmationDialog from "src/Components/ConfirmationDialog";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import NavItem from "./NavItem";
const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: "/images/dashboardIcon/dashboardIcon.svg",
        href: "/dashboard",
      },

      {
        title: "KYC",
        icon: "/images/dashboardIcon/kycIcon.svg",
        href: "/kyc",
      },
      {
        title: "Invest",
        icon: "/images/dashboardIcon/investIcon.svg",
        href: "/invest-dashboard",
      },
      {
        title: "Taxation",
        icon: "/images/dashboardIcon/taxationIcon.svg",
        href: "/dashboard-taxation",
      },
      {
        title: "Regulatory",
        icon: "/images/dashboardIcon/regulatory.svg",
        href: "/regulatory",
      },
      {
        title: "Rewards",
        icon: "/images/dashboardIcon/contactIcon.svg",
        href: "/Rewards",
      },
      {
        title: "Document",
        icon: "/images/dashboardIcon/contactIcon.svg",
        href: "/upload-document",
      }
    ],
  },
];
const sectionsAdmin = [
  {
    items: [
      {
        title: "User Management",
        icon: "/images/dashboardIcon/dashboardIcon.svg",
        href: "/User-management",
      },

      {
        title: "KYC Management",
        icon: "/images/dashboardIcon/kycIcon.svg",
        href: "/kyc-management",
      },
      {
        title: "Referral Management",
        icon: "/images/dashboardIcon/kycIcon.svg",
        href: "/referral-management",
      },
      {
        title: "Meeting Management",
        icon: "/images/dashboardIcon/kycIcon.svg",
        href: "/meeting-management",
      },
    ],
  },
];
function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: `#000`,
    boxShadow: " 0 0.1rem 0.7rem rgb(0 0 0 / 10%)",
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: "100%",
    background: `#0E0E0E`,
    boxShadow: "-6px 0px 8px 0px #090909",
    border: "none",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(1),
    color: `${theme.palette.text.primary} !important`,
  },

  Terms: {
    color: theme.palette.text.primary,
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    borderLeft: "solid 8px transparent",
    borderRadius: 0,
    fontSize: "13px",
    "& .MuiButton-label": {
      padding: "10px",
    },
    "&:hover": {
      "& .MuiButton-label": {
        color: "#fff !important",
        background: "#3A96DD",
        padding: "10px",
        borderRadius: "9px",
        fontWeight: theme.typography.fontWeightRegular,
        "& $title": {
          fontWeight: theme.typography.fontWeightMedium,
        },
        "& $icon": {
          color: "#fff !important",
          // color: "00e0b0",
        },
      },
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
        // color: `${theme.palette.text.primary} !important`,
      },
    },
  },

  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  bottomBox: {
    position: "absolute",
    bottom: 5,
    left: "auto",
    right: "auto",
    width: "100%"
  },
  signOutButton: {
    color: "#FF0918",
    background: "transparent",
    border: "none"
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const role = window.localStorage.getItem("role");
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const confirmationHandler = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("email");
    history.push("/");
    window.localStorage.clear();
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location?.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className="srollBoxNav"
      style={{ overflowY: "auto" }}
    >
      <Hidden mdDown>
        <Box
          mb={-1}
          display="flex"
          justifyContent="center"
        // alignItems="center"
        >
          <img src="/images/logo/logo.svg" alt="logo"
            onClick={() => history.push("/")}

            style={{
              paddingTop: "30px",
              cursor: "pointer",
              width: "87%",
            }}
          />
        </Box>
      </Hidden>
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"Logout"}
          desc={"Do you want to logout ?"}
          confirmationHandler={confirmationHandler}
          style={{ color: "#fff" }}
        />
      )}
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box my={3} style={{ marginTop: "55px" }}>
          {(role === "ADMIN" ? sectionsAdmin : sections).map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location?.pathname,
              })}
            </List>
          ))}
          <Box style={{ height: "100px !important" }}>
            {/* <ChartList height="38%" /> */}
          </Box>
        </Box>

        <Box className={classes.bottomBox}>
          <button onClick={() => setOpen(true)} className={classes.signOutButton}><span><img src="/images/dashboardIcon/signoutIcon.svg" alt="signoutIcon" /></span>&nbsp;&nbsp;<span>Sign Out</span></button>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          style={{ overflowY: "scroll" }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
          style={{ overflowY: "scroll" }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;

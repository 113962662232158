import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { calculateTimeLeft } from "src/View/Auth/ForgotPassword/timer";
import ApiConfig from "src/config/APIConfig";
import { getRequestWithToken } from "src/service";
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("token");
  return accessToken ? true : false;
}


export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [open, setOpen] = React.useState(false);
  const [authType, setAuthType] = useState("");
  const [profile, setProfile] = useState({});
  const handleAuthOpen = () => {
    setOpen(true);
  };
  const handleAuthClose = () => {
    setOpen(false);
  };

  const getProfile = async () => {
    try {
      const url = ApiConfig.getProfile;
      const res = await getRequestWithToken(url);

      if (res.responseCode === 200) {
        const profile = res.responseData;
        setProfile(profile);
      }

    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => { getProfile() }, [])
  useEffect(() => {
    checkLogin();
  }, [window.localStorage.getItem("token")]);
  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });





  let data = {
    isLogin: isLogin,
    setEndtime,
    setTimeLeft,
    timeLeft,
    endTime,
    profile:profile,
    userLogin: (token, isLogin) => {
      setSession(token);
      setIsLogin(isLogin);
    },
    authType,
    open,
    setAuthType,
    handleAuthOpen,
    handleAuthClose,
    getProfile:getProfile
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}

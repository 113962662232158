import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "./Layouts/HomeLayout";
import DashboardLayout from "./Layouts/DashboardLayout";
export const routes = [

  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/IntroPages/Home")),
  },
  {
    exact: true,
    path: "/mutual-funds",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/IntroPages/Invest_mutual_fund/index")),
  },
  {
    exact: true,
    path: "/demat-account",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/IntroPages/invest_demat_account")),
  },
  {
    exact: true,
    path: "/bonds-fds",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/IntroPages/invest_bonds_fds")),
  },
  {
    exact: true,
    path: "/government-bonds",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/IntroPages/governmentBonds")),
  },
  {
    exact: true,
    path: "/corporate-bonds",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/IntroPages/corporateBonds")),
  },
  {
    exact: true,
    path: "/why-india",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/IntroPages/whyIndia")),
  },
  {
    exact: true,
    path: "/invest-fd",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/IntroPages/investFd")),
  },
  {
    exact: true,
    path: "/recovery",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/IntroPages/recovery")),
  },
  {
    exact: true,
    path: "/taxation",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/IntroPages/taxation")),
  },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/IntroPages/about/index")),
  },
  {
    exact: true,
    path: "/invest",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/IntroPages/invest")),
  },
  {
    exact: true,
    path: "/kyc-home",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/IntroPages/kyc")),
  },
  {
    exact: true,
    path: "/pancard",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/IntroPages/panCard")),
  },
  {
    exact: true,
    path: "/banking",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/IntroPages/banking")),
  },
  {
    exact: true,
    path: "/lic-policy",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/IntroPages/licPolicy")),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/dashboard")),
  },
  {
    exact: true,
    path: "/kyc",
    layout: DashboardLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/kyc")),
  },
  // {
  //   exact: true,
  //   path: "/dashboard-taxation",
  //   layout: DashboardLayout,
  //   // guard:true,
  //   component: lazy(() => import("./View/Pages/bookMeeting")),
  // },
  {
    exact: true,
    path: "/dashboard-taxation",
    layout: DashboardLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/taxation")),
  },
  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/editProfile")),
  },
  {
    exact: true,
    path: "/invest-dashboard",
    layout: DashboardLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/bookMeeting")),
  },
  {
    exact: true,
    path: "/Rewards",
    layout: DashboardLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/contact")),
  },
  {
    exact: true,
    path: "/book-meeting",
    layout: DashboardLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/bookMeeting")),
  },
  {
    exact: true,
    path: "/free-meeting",
    layout: DashboardLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/bookMeeting")),
  },
  {
    exact: true,
    path: "/user-management",
    layout: DashboardLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/userManagement")),
  },
  {
    exact: true,
    path: "/kyc-management",
    layout: DashboardLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/kycManagement")),
  },
  {
    exact: true,
    path: "/referral-management",
    layout: DashboardLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/referralManagement")),
  },
  {
    exact: true,
    path: "/meeting-management",
    layout: DashboardLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/MeetingManagement/index")),
  },
  {
    exact: true,
    path: "/meeting-details",
    layout: DashboardLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/MeetingManagement/detail")),
  },
  {
    exact: true,
    path: "/add-minutes-of-meetings",
    layout: DashboardLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/MeetingManagement/addMOM")),
  },
  {
    exact: true,
    path: "/user-details",
    layout: DashboardLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/userDetails")),
  },
  {
    exact: true,
    path: "/terms&condition",
    layout: HomeLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/static/terms")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/static/privacy")),
  },
  {
    exact: true,
    path: "/aadhar",
    layout: HomeLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/IntroPages/aadhar")),
  },
  {
    exact: true,
    path: "/company-incorporation",
    layout: HomeLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/IntroPages/incorporation")),
  },
  {
    exact: true,
    path: "/regulatory",
    layout: DashboardLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/regulatory")),
  },
  {
    exact: true,
    path: "/upload-document",
    layout: DashboardLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/mom")),
  },
  {
    exact: true,
    path: "/invest-management",
    layout: DashboardLayout,
    // guard:true,
    component: lazy(() => import("./View/Pages/investManagement/index.js")),
  },
  // {
  //   exact: true,
  //   path: "/login",
  //   layout:"",
  //   component: lazy(() => import("./View/Auth")),
  // },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("./View/Error/notFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];



// SideDrawer.js
import React, { useState } from "react";
import "./sideBar.css"; // Import your CSS for styling
import CloseIcon from "@material-ui/icons/Close";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory, Link } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const data = [
  { name: "Taxation", id: "", href: "/taxation" },
  // { name: "Recovery", id: "", href: "/recovery" },
  { name: "Why India?", id: "", href: "/why-india" },
  { name: "About Us", id: "", href: "/about" },
];

const selectData = [
  { name: "Investment Plans", href: "/invest" }, 
  { name: "Mutual Fund", href: "/mutual-funds" }, 
  { name: "Demat Account", href: "/demat-account" }, 
  { name: "Corporate Bonds", href: "/corporate-bonds" }, 
  { name: "Government Bonds", href: "/government-bonds" }, 
  { name: "Fixed Deposits", href: "/invest-fd" },
  { name: "LIC", id: "", href: "/lic-policy" },
];

const selectRegulatory = [
  { name: "Demat Account", href: "/demat-account" }, 
  { name: "PAN Card", id: "", href: "/pancard" },
  { name: "Aadhar Card", id: "", href: "/aadhar" },
  { name: "Company Incorporation", id: "", href: "/company-incorporation" },
  { name: "Banking", id: "", href: "/banking" },
  { name: "Share Recovery", id: "", href: "/recovery" }
];

function SideDrawer(props) {
  const history = useHistory();
  const { isOpen, onClose } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuType, setMenuType] = useState("");

  const handleClick = (event, type) => {
    setAnchorEl(event.currentTarget);
    setMenuType(type);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (href) => {
    handleClose();
    history.push(href);
  };

  return (
    <div className={`side-drawer ${isOpen ? "open" : ""}`}>
      <nav>
        <div className="close-div" onClick={onClose}>
          <CloseIcon />
        </div>
        <ul>
          <li style={{ cursor: "pointer", display: "flex", justifyContent: "center", fontWeight: 700 }} onClick={(e) => handleClick(e, "INVEST")}>
            Investment<ExpandMoreIcon />
          </li>
          {data.slice(0, 1).map((item, i) => (
            <li key={i} style={{ cursor: "pointer", display: "flex", justifyContent: "center", fontWeight: 700 }}>
              <Link to={item.href} style={{ color: "#fff", textDecoration: "none" }}>
                {item.name}
              </Link>
            </li>
          ))}
          <li style={{ cursor: "pointer", display: "flex", justifyContent: "center", fontWeight: 700 }} onClick={(e) => handleClick(e, "TAXATION")}>
            Regulatory<ExpandMoreIcon />
          </li>
          {data.slice(1).map((item, i) => (
            <li key={i + 1} style={{ cursor: "pointer", display: "flex", justifyContent: "center", fontWeight: 700 }}>
              <Link to={item.href} style={{ color: "#fff", textDecoration: "none" }}>
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
        <Menu
          className="menu"
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {menuType === "INVEST" ? (
            selectData.map((item, index) => (
              <MenuItem key={index} onClick={() => handleMenuItemClick(item.href)}>{item.name}</MenuItem>
            ))
          ) : (
            selectRegulatory.map((item, index) => (
              <MenuItem key={index} onClick={() => handleMenuItemClick(item.href)}>{item.name}</MenuItem>
            ))
          )}
        </Menu>
      </nav>
    </div>
  );
}

export default SideDrawer;
